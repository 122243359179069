<template>
  <div class="home">
    <v-row>
      <v-col cols="4">
        <!-- <h2>Bem vindo ao Naja</h2> -->
        <div class="nj-component">
          <h4>Status de conexão</h4>
          <v-divider class="mb-4" />
          <div v-if="status.loading" class="loading-container">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </div>
          <div v-else class="font-14">
            <p>
              <v-icon v-if="status.ativo" color="green">mdi-check</v-icon>
              <v-icon v-else color="red">mdi-close</v-icon>
              Conexão com Web Service ({{ status.version }})
            </p>
            <p>
              <v-icon v-if="status.banco" color="green">mdi-check</v-icon>
              <v-icon v-else color="red">mdi-close</v-icon>
              Conexão com banco de dados
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from '@/resources/NajaSocialApi'
export default {
  name: 'Home',
  data: () => ({
    status: {
      loading: false,
      ativo: null,
      banco: null,
      version: null
    },
  }),
  mounted() {
    this.getStatus()
  },
  methods: {
    getStatus() {
      this.status.loading = true
      Api.organizationStatus()
        .then(response => {
          this.status = {
            ...this.status,
            ...response.data,
          }
        })
        .catch(error => {
          throw error
        })
        .finally(() => {
          this.status.loading = false
        })
    },
  },
}
</script>
